@charset "UTF-8";
/*

	Document   	: estilos.css
	Autor		: @douglasdsbraga - designer digital and front-end developer

*/
:root {
  --colorAzul: #0F1631;
  --colorAmarelo: #0F1631;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background-color: var(--colorAmarelo);
  margin: 0 auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
}
body::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  background: #333;
}
body::-webkit-scrollbar-thumb {
  background: #fc0;
  transition: ease-in-out 0.3s all;
}
body::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.main {
  overflow: hidden;
  background-color: #fff;
}

header h1, header h2, header h3, header h4, header h5,
footer h1,
footer h2,
footer h3,
footer h4,
footer h5 {
  margin: 0;
}
header a,
footer a {
  text-decoration: none;
}

nav a {
  text-decoration: none;
}

/* recuros do layout ===================== */
.listStyleNone, .social, .listLink {
  padding: 0;
  margin: 0;
  list-style: none;
}

.textNone {
  text-indent: -9999px !important;
  overflow: hidden !important;
  outline: none !important;
}

.aparence {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.backSpec {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
}

.imgPadrao, .imgSetup img {
  display: block;
  width: 100%;
  height: auto !important;
  border: none;
}

.imgSetup {
  display: block;
  width: 100%;
  margin: 0;
}
.bgBlack {
  background-color: #000;
  position: relative;
  z-index: 100;
}
.bgBlack.firstBOX::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -99.99%;
  background-color: #000;
}
@media screen and (max-width: 992px) {
  .bgBlack.firstBOX::before {
    display: none;
  }
}
.bgBlack.firstBOX .sidebarFixed {
  position: fixed;
  top: 8vw;
  left: 6vw;
  width: 30vw;
}
@media screen and (max-width: 1170px) {
  .bgBlack.firstBOX .sidebarFixed {
    top: 5vw;
  }
  .bgBlack.firstBOX .sidebarFixed .titleSession.fz-36 {
    font-size: 2.5vw;
  }
  .bgBlack.firstBOX .sidebarFixed h2 {
    font-size: 1.67vw;
  }
}
@media screen and (max-width: 992px) {
  .bgBlack.firstBOX .sidebarFixed {
    position: relative;
    top: 0;
    margin-top: 10vw;
    left: 0;
    width: 100%;
  }
  .bgBlack.firstBOX .sidebarFixed .logobrand {
    margin: 0 auto;
  }
  .bgBlack.firstBOX .sidebarFixed .titleSession.fz-36 {
    font-size: 6vw;
    text-align: center;
  }
  .bgBlack.firstBOX .sidebarFixed .social {
    text-align: center;
    justify-content: center !important;
  }
  .bgBlack.firstBOX .sidebarFixed .social li {
    margin: 0 1vw;
  }
  .bgBlack.firstBOX .sidebarFixed h2 {
    font-size: 3vw;
    text-align: center;
  }
}
@media screen and (max-width: 740px) {
  .bgBlack.firstBOX .sidebarFixed .titleSession.fz-36 {
    font-size: 7vw;
  }
  .bgBlack.firstBOX .sidebarFixed h2 {
    font-size: 3.9vw !important;
  }
}

.bgPalco {
  position: relative;
  z-index: 10;
}

.menuSanduiche {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 24px;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.menuSanduiche span {
  display: block;
  z-index: 1;
  width: 100%;
  height: 3px;
  background-color: rgb(0, 0, 0);
}
.menuSanduiche:hover span:nth-child(3) {
  width: 66.6666666667%;
}

.t3s {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.t5s {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.t7s {
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.t100s {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.ctaBox {
  width: 300px;
}
.ctaBox .cta {
  padding: 0.7em 0;
}
.ctaBox .cta:nth-child(1) {
  margin-bottom: 1em;
}

.cta {
  padding: 0.625em 1em;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  border-radius: 7px;
  padding: 1em 3em;
  border: none;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}
.cta span {
  display: block;
}
.cta.cta-inline {
  text-transform: none;
}
.cta.cta-inline:hover {
  text-decoration: underline;
  box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -o-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
}
.cta.fullsize {
  display: block;
  width: 100% !important;
}
.cta.fz-14 {
  font-size: 14px;
}
.cta.fz-18 {
  font-size: 14px;
}
.cta.text-bold {
  font-weight: 700;
}
.cta.text-normal {
  text-decoration: none;
  text-transform: none;
}
.cta.padd-laranja-button {
  padding: 1em 1.5em;
}
.cta.color-preto {
  color: #0F1631;
}
.cta.color-vermelho {
  color: #0F1631;
}
.cta.color-branco {
  color: #fff;
}
.cta.bg-vermelho {
  background-color: #0F1631;
}
.cta.bg-laranja {
  background-color: #0F1631;
}
.cta.bg-amarelo {
  background-color: #EDD8C6;
}
.cta.bg-preto {
  background-color: #816434;
}
.cta.bg-branco {
  background-color: #fff;
}
.cta.d-inline {
  display: inline-block !important;
}
.cta.gradient-laranja {
  background-image: linear-gradient(180deg, rgba(237, 216, 198, 0.9) 0%, rgba(15, 22, 49, 0.9) 100%);
}
.cta.gradient-vermelho {
  background-image: linear-gradient(180deg, rgba(220, 29, 43, 0.9) 0%, rgba(176, 30, 40, 0.9) 100%);
}
.cta.bg-alpha-zero {
  background-color: transparent;
  padding: 0;
}
.cta.bg-alpha-zero:hover {
  box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
  -o-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0);
}
.cta.bg-alpha-zero.color-vermelho:hover {
  color: #0F1631;
}
.cta.border-all:hover {
  background-color: #0F1631;
  color: #fff;
}
.cta:hover {
  background-color: #fc0;
  box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0.45);
  -ms-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0.45);
  -o-box-shadow: 0 5px 13px 0px rgba(0, 0, 0, 0.45);
}

/* contatcForm ----------------------------- */
.contactForm fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.contactForm input[type=text], .contactForm textarea, .contactForm .selectBox, .contactForm .boxDefault, .contactForm label.inputFile, .contactForm label.time, .contactForm label.date {
  width: 100%;
  height: 40px;
  padding: 0px 0.7em;
  outline: none;
  display: block;
  position: relative;
  z-index: 20;
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 2.5;
  font-family: 1;
  font-weight: 400;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 1em;
  background: transparent;
}
.contactForm label {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 2.5;
  font-family: 1;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
}
.contactForm label em {
  float: left;
  margin-left: 1em;
  line-height: 32px;
}
.contactForm label input {
  float: left;
}
.contactForm label.selectBox {
  position: relative;
  width: 100%;
  padding: 0;
}
.contactForm label.selectBox select {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0px 1em;
  border: none;
  display: block;
  color: #816434;
  font-size: 14px;
  line-height: 2.5;
  font-family: 1;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
.contactForm label.selectBox option {
  background-color: #fff;
  color: #816434;
}
.contactForm label.date {
  position: relative;
  width: 100%;
  padding: 0;
  border: 1px solid #7e7d7e;
}
.contactForm label.date input[type=date] {
  outline: none;
  position: relative;
  z-index: 10;
  width: calc(100% - 1em);
  height: 2.4em;
  padding: 0px 1em;
  margin-bottom: 1em;
  border: none;
}
.contactForm label.date::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 1em;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 18px;
  height: 16px;
  background-image: url("../img/icone/date.png");
}
.contactForm label.time {
  position: relative;
  width: 100%;
  padding: 0;
}
.contactForm label.time input[type=time] {
  outline: none;
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0px 1em;
  margin-bottom: 1em;
}
.contactForm label.time::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 1em;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5px;
  height: 20px;
  background-image: url("../img/icone/select.png");
}
.contactForm label.inputFile {
  position: relative;
  width: 100%;
  padding: 0;
  border: 1px solid #7e7d7e;
}
.contactForm label.inputFile input[type=file] {
  outline: none;
  position: relative;
  z-index: 10;
  width: 100%;
  height: 2.4em;
  padding: 0px 1em;
  margin-bottom: 1em;
  border: none !important;
  opacity: 0;
  cursor: pointer;
}
.contactForm label.inputFile em {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-style: normal;
  cursor: pointer;
  display: block;
  color: #816434;
  font-size: 14px;
  line-height: 2.7;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
}
.contactForm label.checkBox {
  width: 100%;
  overflow: hidden;
}
.contactForm label.checkBox input[type=checkbox] {
  display: inline-block;
}
.contactForm label.checkBox em {
  display: block;
  width: calc(100% - 1.5em);
  float: right;
  margin-bottom: 1em;
  margin-left: 0;
  line-height: 1;
  font-style: normal;
}
.contactForm input[type=text] {
  line-height: 32px;
}
.contactForm input[name=randomfield] {
  width: 70px;
  margin-bottom: 0;
}
.contactForm textarea {
  height: 10.5em;
  resize: none;
  line-height: 1.5;
  padding-top: 0.5em;
}
.contactForm textarea.msnServico {
  height: 6em;
}
.contactForm input[type=submit] {
  width: 100%;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  color: #fff;
  background-color: #0C3A79;
}
.contactForm ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.contactForm ::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.contactForm :-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.contactForm :-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

.owl-carousel#cliente {
  margin-bottom: 2em;
}
.owl-carousel#cliente .owl-nav {
  display: none;
}
.owl-carousel#cliente .owl-dots {
  position: absolute;
  left: 0;
  bottom: -6em;
  z-index: 1;
}
.owl-carousel .owl-stage-outer {
  overflow: visible !important;
}
.owl-carousel .owl-dots {
  display: block;
  width: 100%;
  font-size: 12px;
  margin-top: 1em;
}
.owl-carousel .owl-dots .owl-dot {
  background-color: transparent;
  border: 1px solid #fc0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: none;
  margin-right: 0.5em;
}
.owl-carousel .owl-dots .owl-dot.active {
  background-color: #fc0;
}

.gcBox {
  display: block;
  color: #fff;
  line-height: 1.5;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  color: white;
}
.gcBox.padd4em {
  padding: 0 0 6em;
}
.gcBox a {
  color: #fff;
}
@media screen and (min-width: 1440px) {
  .gcBox {
    font-size: 17px;
  }
}
@media screen and (min-width: 1920px) {
  .gcBox {
    font-size: 18px;
  }
}
.gcBox .embed-responsive {
  margin-bottom: 1em;
}
.gcBox.fz-16 {
  font-size: 16px;
}
.gcBox.fz-18 {
  font-size: 18px;
}
.gcBox.color-branco {
  color: #D59C1B;
}

.gcBox p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.gcBox a {
  text-decoration: underline;
}

.gcBox h1 {
  font-size: 22px;
}
@media screen and (min-width: 740px) {
  .gcBox h1 {
    font-size: 22px;
  }
}
@media screen and (min-width: 992x) {
  .gcBox h1 {
    font-size: 28px;
  }
}
@media screen and (min-width: 1200px) {
  .gcBox h1 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1400px) {
  .gcBox h1 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1600px) {
  .gcBox h1 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1920px) {
  .gcBox h1 {
    font-size: 32px;
  }
}

.gcBox h2 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 20px;
}
@media screen and (min-width: 740px) {
  .gcBox h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 992x) {
  .gcBox h2 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .gcBox h2 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1400px) {
  .gcBox h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1600px) {
  .gcBox h2 {
    font-size: 24px;
  }
}

.gcBox h3 {
  font-size: 18px;
  font-weight: 700 !important;
}
@media screen and (min-width: 740px) {
  .gcBox h3 {
    font-size: 20px;
  }
}
@media screen and (min-width: 992x) {
  .gcBox h3 {
    font-size: 19px;
  }
}
@media screen and (min-width: 1200px) {
  .gcBox h3 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1400px) {
  .gcBox h3 {
    font-size: 22px;
  }
}

.gcBox h4 {
  font-size: 16px;
  font-weight: 400;
}

.gcBox h5 {
  font-size: 14px;
  font-weight: 400;
}

.gcBox h6 {
  font-size: 14px;
  font-weight: 400;
}

.gcBox b {
  font-weight: 700;
  color: #000;
}

.gcBox i {
  font-style: oblique;
}

.gcBox sub {
  vertical-align: sub;
  font-size: smaller;
}

.gcBox sup {
  vertical-align: super;
  font-size: smaller;
}

.gcBox blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}

.gcBox ul {
  padding-left: 20px;
}

.gcBox ul li {
  list-style: none;
  position: relative;
  padding-left: 1em;
}

.gcBox ul li::before {
  content: "•";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  top: 0.02em;
}

.gcBox ol {
  padding-left: 20px;
}

.gcBox ol li {
  list-style: decimal;
}

.gcBox strong {
  font-weight: 700;
}

.gcBox img {
  display: block;
  width: 100%;
  height: auto !important;
}

/*
  # referencia
  @include font-size($fs-ate48px);

  # sumario
  1. titlesPadrão
  2. textPadrão

*/
.titleMain {
  display: block;
  line-height: 1;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
}

.subtitle {
  display: block;
  line-height: 1;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
}

.textDefault {
  display: block;
  line-height: 1;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
}

.navMain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 1.67rem 1rem;
}
@media screen and (max-width: 740px) {
  .navMain {
    justify-content: center;
  }
}
.navMain .titleSession a {
  display: inline-block;
  color: currentColor;
}
.navMain .titleSession a:hover {
  color: #fc0;
}
.navMain ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 3rem;
}
@media screen and (max-width: 740px) {
  .navMain ul {
    margin-left: 0;
  }
}
.navMain ul li {
  margin-left: 1rem;
}
.navMain ul li a {
  color: #fff;
  display: block;
  transition: all 0.3s ease-in-out;
}
.navMain ul li a:hover, .navMain ul li a.ativo {
  color: #fc0;
}

.titleSession {
  position: relative;
  margin: 0;
  display: block;
}
.titleSession a {
  display: inline-block;
  color: currentColor;
  text-decoration: none;
}
.titleSession.font-light {
  font-weight: 300 !important;
}
.titleSession.font-regular {
  font-weight: 400 !important;
}
.titleSession.font-bold {
  font-weight: 700 !important;
}
.titleSession.title-center {
  text-align: center;
}
.titleSession.title-left {
  text-align: left;
}
.titleSession.title-right {
  text-align: right;
}
.titleSession.title-normal {
  font-style: normal !important;
  text-transform: none !important;
}

.titleSession.fz-14 {
  font-size: 14px;
}
.titleSession.fz-16 {
  font-size: 16px;
}
.titleSession.fz-18 {
  font-size: 18px;
}
.titleSession.fz-22 {
  font-size: 18px;
}
@media screen and (min-width: 740px) {
  .titleSession.fz-22 {
    font-size: 20px;
  }
}
@media screen and (min-width: 992x) {
  .titleSession.fz-22 {
    font-size: 19px;
  }
}
@media screen and (min-width: 1200px) {
  .titleSession.fz-22 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1400px) {
  .titleSession.fz-22 {
    font-size: 22px;
  }
}
.titleSession.fz-24 {
  font-size: 20px;
}
@media screen and (min-width: 740px) {
  .titleSession.fz-24 {
    font-size: 24px;
  }
}
@media screen and (min-width: 992x) {
  .titleSession.fz-24 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .titleSession.fz-24 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1400px) {
  .titleSession.fz-24 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1600px) {
  .titleSession.fz-24 {
    font-size: 24px;
  }
}
.titleSession.fz-32 {
  font-size: 22px;
}
@media screen and (min-width: 740px) {
  .titleSession.fz-32 {
    font-size: 22px;
  }
}
@media screen and (min-width: 992x) {
  .titleSession.fz-32 {
    font-size: 28px;
  }
}
@media screen and (min-width: 1200px) {
  .titleSession.fz-32 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1400px) {
  .titleSession.fz-32 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1600px) {
  .titleSession.fz-32 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1920px) {
  .titleSession.fz-32 {
    font-size: 32px;
  }
}
.titleSession.fz-36 {
  font-size: 20px;
}
@media screen and (min-width: 740px) {
  .titleSession.fz-36 {
    font-size: 22px;
  }
}
@media screen and (min-width: 992x) {
  .titleSession.fz-36 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .titleSession.fz-36 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1400px) {
  .titleSession.fz-36 {
    font-size: 36px;
  }
}
@media screen and (min-width: 1600px) {
  .titleSession.fz-36 {
    font-size: 36px;
  }
}
@media screen and (min-width: 1920px) {
  .titleSession.fz-36 {
    font-size: 36px;
  }
}
.titleSession.fz-42 {
  font-size: 22px;
}
@media screen and (min-width: 740px) {
  .titleSession.fz-42 {
    font-size: 36px;
  }
}
@media screen and (min-width: 992x) {
  .titleSession.fz-42 {
    font-size: 42px;
  }
}
@media screen and (min-width: 1200px) {
  .titleSession.fz-42 {
    font-size: 36px;
  }
}
@media screen and (min-width: 1400px) {
  .titleSession.fz-42 {
    font-size: 42px;
  }
}
@media screen and (min-width: 1600px) {
  .titleSession.fz-42 {
    font-size: 42px;
  }
}
@media screen and (min-width: 1920px) {
  .titleSession.fz-42 {
    font-size: 42px;
  }
}
.titleSession.fz-128 {
  font-size: 10vw;
}
@media screen and (min-width: 740px) {
  .titleSession.fz-128 {
    font-size: 72px;
  }
}
@media screen and (min-width: 992x) {
  .titleSession.fz-128 {
    font-size: 92px;
  }
}
@media screen and (min-width: 1200px) {
  .titleSession.fz-128 {
    font-size: 92px;
  }
}
@media screen and (min-width: 1400px) {
  .titleSession.fz-128 {
    font-size: 128px;
  }
}
@media screen and (min-width: 1600px) {
  .titleSession.fz-128 {
    font-size: 128px;
  }
}
@media screen and (min-width: 1920px) {
  .titleSession.fz-128 {
    font-size: 128px;
  }
}

.titleSession.color-vermelho {
  color: #0F1631;
}
.titleSession.color-laranja {
  color: #0F1631;
}
.titleSession.color-preto {
  color: #816434;
}
.titleSession.color-amarelo {
  color: #fc0;
}
.titleSession.color-branco {
  color: #fff;
}
.titleSession.color-whatsapp {
  color: #47C53E;
}
.titleSession.color-facebook {
  color: #39589A;
}
.titleSession.color-twiiter {
  color: #049FF6;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
  fill: currentColor;
}
.icon.inline {
  display: inline-block;
}
.icon.icon-12 {
  width: 12px;
  height: 18px;
}
.icon.icon-15 {
  width: 15px;
  height: 15px;
}
.icon.icon-18 {
  width: 18px;
  height: 18px;
}
.icon.icon-30 {
  width: 30px;
  height: 30px;
}
.icon.icon-32 {
  width: 32px;
  height: 32px;
}
.icon.icon-60 {
  width: 60px;
  height: 22px;
}
.icon.icon-48 {
  width: 48px;
  height: 48px;
}
.icon.icon-video {
  width: 14px;
  height: 17px;
}
.icon.icon-playerSobre {
  width: 48px;
  height: 52px;
}
.icon.icon-dropDown {
  width: 10px;
  height: 10px;
}
.icon.icon-slide-nav {
  width: 14px;
  height: 72px;
}
.icon.icon-caroucel-nav {
  width: 7px;
  height: 16px;
}
.icon.icon-dropdown-circle {
  width: 4px;
  height: 18px;
}
.icon.icon-arrow-right-conteudo {
  width: 38px;
  height: 10px;
}
.icon.icon-white {
  color: #fff;
}
.icon.icon-cinza {
  color: #d1d3d4;
}
.icon.icon-azul-2 {
  color: #022249;
}
.icon.icon-cinza-azul {
  color: #6D6D6D;
}

.logobrand {
  max-width: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.social {
  margin-top: 1em;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.social a {
  color: #fff;
  display: block;
}
.social li {
  margin: 0px 0.67em;
}

.listLink li, .listLink .itemListLink {
  margin-top: 1em;
}
.listLink li a, .listLink .itemListLink a {
  color: #fff;
}
.listLink li a:hover, .listLink .itemListLink a:hover {
  background-color: #fc0;
}
.listLink li:nth-child(8n+1) a, .listLink .itemListLink:nth-child(8n+1) a {
  background-color: #5b137f;
  color: #fc0;
}
.listLink li:nth-child(8n+2) a, .listLink .itemListLink:nth-child(8n+2) a {
  background-color: #fc0082;
}
.listLink li:nth-child(8n+3) a, .listLink .itemListLink:nth-child(8n+3) a {
  background-color: #ff6a00;
}
.listLink li:nth-child(8n+4) a, .listLink .itemListLink:nth-child(8n+4) a {
  background-color: #5C08E7;
}
.listLink li:nth-child(8n+5) a, .listLink .itemListLink:nth-child(8n+5) a {
  background-color: #006dfc;
}
.listLink li:nth-child(8n+6) a, .listLink .itemListLink:nth-child(8n+6) a {
  background-color: #2FB554;
}
.listLink li:nth-child(8n+7) a, .listLink .itemListLink:nth-child(8n+7) a {
  background-color: transparent;
  border: 1px solid #fc0;
  color: #fc0;
}
.listLink li:nth-child(8n+8) a, .listLink .itemListLink:nth-child(8n+8) a {
  background-color: #254bd3;
}
.listLink .itemListNavSuperior {
  margin-top: 1em;
}
.listLink .itemListNavSuperior a {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fc0;
  color: #fc0;
  transition: all 0.3s ease-in-out;
}
.listLink .itemListNavSuperior a:hover {
  color: #5b137f;
  background-color: #fc0;
}
.listLink a {
  display: block;
  width: 100%;
  padding: 1em 0;
  background-color: #fff;
  color: #222;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
}

.listCliente a {
  color: #fc0;
  text-decoration: underline;
}
.listCliente .coverCliente {
  border-radius: 17px;
  overflow: hidden;
  margin: 0 0 0.67em;
}
.listCliente .coverCliente a {
  padding: 0;
}
.listCliente #clientes:hover {
  cursor: grab;
}

.videoPlay {
  display: block;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.videoPlay .oVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.videoPlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: #000;
  mix-blend-mode: multiply;
  opacity: 0.67;
}
.videoPlay .player {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  color: #fff;
}
.videoPlay .textBox {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 30;
  padding: 1rem 1rem 2rem;
}

.banner {
  background-color: #fc0;
  color: #5b137f;
  padding: 10px 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.banner p {
  font-size: 18px;
  margin: 0;
}

.coverVideoBox {
  overflow: hidden;
}
.coverVideoBox a .oVideo {
  width: 126%;
  transform: translateX(-7em);
}
@media screen and (max-width: 740px) {
  .coverVideoBox a .oVideo {
    width: 100%;
    transform: translateX(0);
  }
}

/* Desktops
================================================ */
/* Tablets
=========================================== */
/* Smartphone
=========================================== */
/* Desktops
================================================ */
/* Tablets
=========================================== */
/* Smartphone
=========================================== */
/* iPhone 6+ - referencia  */
/* iPhone 6 - referencia  */
/* iPhone 5 - referencia  */